<script>

import AddressIcon from './addressSVG.svelte'
import PhoneIcon from './phoneSVG.svelte'
import EmailIcon from './emailSVG.svelte'

let formData = {
  email: '',
  subject: '',
  message: ''
}

const handleSubmit = async () => {
  try {
    const response = await fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    
    if (response.ok) {
      const data = await response.json()
      console.log('Success:', data)
      Toastify({
        text: "¡Correo enviado!",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top", 
        position: "right", 
        backgroundColor: "rgb(249 115 22)",
        colorText: 'black',
      }).showToast();
      
      formData = {
        email: '',
        subject: '',
        message: ''
      }
    } else {
      console.error('Error:', response.statusText)
    }
  } catch (error) {
    console.error('Error:', error)
  }
}

</script>

<!-- book section -->
<section class="bg-white py-10 md:py-16" id="formComunicate">
  <div class="container max-w-screen-xl mx-auto px-4 xl:relative">
    <div class="form-s gradient flex flex-col lg:flex-row items-center justify-evenly py-14 lg:px-16 sm:px-6 rounded-3xl">
      <div class="text-center lg:text-left mb-10 lg:mb-0 max-w-xl">
        <h3 class="font-semibold text-white text-4xl md:text-5xl lg:text-6xl leading-normal mb-4 pb-4">Comunícate con nosotros <br></h3>

        <div class="p-4 bg-orange-600 md:w-fit w-5/6 rounded-2xl mx-auto lg:m-0">
          <div class="relative pl-10">
            <AddressIcon class="text-green-700"/>
            <h6 class="font-semibold text-white text-left md:text-xl">Cabuyaro Meta, Colombia</h6>
            <p class="font-normal pb-4 text-gray-200 text-left">Cra 8 # 5 – 41 – Barrio Centro</p>
          </div>

          <div class="relative pl-10">
            <PhoneIcon />
            <h6 class="font-semibold text-white text-left md:text-xl">+57 320 9501918</h6>
            <p class="font-normal pb-4 text-gray-200 text-left">Lunes a Viernes 9am a 6pm</p>
          </div>

          <div class="relative pl-10">
            <EmailIcon />
            <h6 class="font-semibold text-white text-left md:text-xl">contactenos@serting.com.co</h6>
            <p class="font-normal pb-4 text-gray-200 text-left">¡Envíenos su consulta!</p>
          </div>
        </div>
      </div>

      <div class="hidden xl:block xl:absolute right-0">
        <img src="/assets/img/book.png" alt="book">
      </div>

      <form on:submit|preventDefault={handleSubmit} method="POST" action="/api/contact" class=" md:block bg-white xl:relative px-6 py-3 rounded-3xl lg:max-w-md md:w-3/5 sm:w-3/4 w-5/6">
        <div class="py-3">
          <h3 class="font-semibold text-gray-900 text-3xl">Contáctanos</h3>
        </div>

        <div class="py-3">
          <input type="email" id="email" name="email" placeholder="Email" bind:value={formData.email} class="px-4 py-4 w-full bg-gray-200 placeholder-gray-400 rounded outline-none text-black" required>
        </div>

        <div class="py-3">
          <input type="text" id="subject" name="subject" placeholder="Asunto" bind:value={formData.subject} class="px-4 py-4 w-full bg-gray-200 placeholder-gray-400 rounded outline-none text-black" required>
        </div>

        <div class="py-3 relative">
          <textarea id="message" name="message" placeholder="Mensaje" bind:value={formData.message} class="px-4 py-4 w-full bg-gray-200 placeholder-gray-400 rounded outline-none text-black" rows="3" cols="30" required></textarea>
        </div>

        <div class="py-3">
          <button type="submit" class="form-btn w-full py-4 font-semibold text-lg text-white gradient rounded-full hover:bg-green-900 hover:scale-105 transition ease-in-out duration-500" >Enviar</button>
        </div>
      </form>
    </div>
  </div> <!-- container.// -->
</section>
